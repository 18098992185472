<template>
  <div class="md-manage-ge-ren-zi-liao">
    <div class="user-info">

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">

          <div class="userinfo">
            <div class="avatar-box">
              <img v-if="userInfo?.avatar" :src="`/video-dev${userInfo?.avatar}`" alt="">
              <img v-else src="@/assets/img/avatar.jpeg" alt="">
              <el-upload :headers="headers" accept=".jpg,.png,.jpeg" action="/video-dev/common/upload" auto-upload
                :show-file-list="false" :before-upload="beforeUpload" :on-success="handleSuccess">
                <div class="over-avatar">更换头像</div>
              </el-upload>
            </div>
            <ul>
              <li v-if="userInfo.phonenumber">
                <b>手机号</b>
                <span>{{ userInfo.phonenumber }}</span>
              </li>
              <li>
                <b>真实姓名</b>
                <span>{{ userInfo.userName }}</span>
              </li>
              <li>
                <b>昵称</b>
                <span><el-input :placeholder="userInfo.nickName" v-model="form.nickName"> </el-input></span>
              </li>
              <li>
                <b>性别</b>
                <span> <el-radio v-model="form.sex" label="0">男</el-radio>
                  <el-radio v-model="form.sex" label="1">女</el-radio></span>
              </li>
            </ul>

            <el-button type="primary" @click="changeinfo(form)">保存</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="修改密码" name="second">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="原始密码" prop="oldPwd">
              <el-input v-model="ruleForm.oldPwd" placeholder="请输入原始密码"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
              <el-input type="password" placeholder="请输入8-16个字符或数字密码" v-model="ruleForm.newPwd"></el-input>
              <p>*请设置6-12位密码</p>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPwd">
              <el-input type="password" placeholder="请再次输入新密码" v-model="ruleForm.confirmPwd"></el-input>
            </el-form-item>
          <div class="makesure"> <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button> </div>
            
          </el-form>

        </el-tab-pane>
        <el-tab-pane label="修改手机号" name="third">功能暂未开放，敬请期待~</el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
import { changepwd, changeinfo } from "@/axios/user";
// import { selectUserStudyStatistics,changeinfo } from '@/axios/user'
export default {
  name: 'MdManageGeRenZiLiaoComponent',
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      activeName: 'first',
      form: {
        // sex: '1',
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("Authorization"),
      },
      ruleForm: {
        newPwd: '',
        confirmPwd: '',
        oldPwd: ''
      },
      rules: {
        newPwd: [
          { required: true, message: '密码长度在 6 到 12 个字符', pattern: /^[0-9a-zA-Z]{6,12}$/, trigger: 'blur' }
        ],
        confirmPwd: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        oldPwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ]
      },

      userInfo: {},
      dialogFormVisible: false,//控制修改密码弹窗的显示隐藏
    };
  },
  created() {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.form.sex=this.userInfo.sex;

  },
  mounted() {
    window.addEventListener("setItemEvent", (e) => {
      console.log(e, '发生改变了赋值');
      this.userInfo = JSON.parse(e.newValue)
    });
  },
  computed: {
    isLogin() {
      return window.localStorage.getItem('isLogin')
    },

  },
  methods: {
    beforeUpload(file) {
      console.log(file, 'file');
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过2M!");
      }
      return isLt2M;
    },
    async changeinfo(data) {
      const res = await changeinfo(data);
      if (res.code == 200) {
        window.localStorage.setItem('userInfo', JSON.stringify({ ...this.userInfo, ...this.form }));
        this.form = { sex: this.form.sex }
        this.$message.success("修改成功");
      }


    },
    handleSuccess(res, file) {
      console.log(res, file, 'res');
      // this.userInfo.avatar = res.fileName;
      window.localStorage.setItem('userInfo', JSON.stringify({ ...this.userInfo, avatar: res.fileName }));
      this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      if (res.code === 200) {
        this.changeinfo({ avatar: res.fileName })
        this.$message.success("上传成功");
      } else {
        this.$message.error(res.msg);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async changepwd(data) {
      const res = await changepwd(data)
      if (res.code == 200) {
        this.$message.success('修改成功')
        this.dialogFormVisible = false
        localStorage.clear()
        window.location.href = "/login"
      }
    },
    changPass() {
      this.dialogFormVisible = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changepwd(this.ruleForm)
        } else {

          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>
<style lang="less" scoped>
.md-manage-ge-ren-zi-liao {
  width: 100%;
  background: #fff;
  padding: 20px;

  ::v-deep {
    .is-active {
      color: #0FB09B !important;
    }

    .el-tabs__active-bar {
      background-color: #0FB09B;
    }

    .el-tabs__item {
      font-size: 16px;
      color: #333333;
    }

    .el-tabs__item:hover {
      color: #0FB09B !important;
    }
  }

  .over-avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    line-height: 140px;
    font-size: 14px;
    opacity: 0;
  }

  .over-avatar:hover {
    transition: all 1s;
    // width: 311px;
    // height: 245px;
    opacity: 1;
  }

  .avatar-box {
    width: 119px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .avater {
    width: 119px;
    height: 120px;
    border-radius: 50%;
  }

  .user-info {
    // margin-top: 10px;
    padding: 0 20px;
    box-sizing: border-box;

    .userinfo {
      >:nth-child(1) {
        width: 119px;
        margin: 0 auto;

        p {
          font-size: 16px;
          margin-top: 12px;
          width: 119px;
          text-align: center;
        }
      }

    }

    ul {
      margin-top: 40px;
      width: 50%;

      li {
        margin-bottom: 32px;
        font-size: 14px;
        line-height: 24px;
        padding-left: 16px;
        display: flex;
        align-items: center;

        .el-input {
          width: 174px;
          height: 36px;
        }

        b {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #808080;
          line-height: 20px;
          width: 70px;
        }

        .el-button--primary {
          background-color: #e0e0e0;
          border: none;
          color: rgba(0, 0, 0, 0.6);
        }

        .el-button--small {
          padding: 13px 15px;
        }

        span {
          font-size: 14px;
          color: #555;


        }
      }
    }
  }
}

.makesure{
  ::v-deep{
    .el-button--primary{
      margin: 0px;
      margin-top: 40px;
    }
  }
}

.demo-ruleForm {
  margin-top: 40px;
}

::v-deep {
  .el-input__inner {
    width: 364px;
    height: 40px;
  }
  .el-form-item__label{
    text-align: left;
  }

  .el-button--primary {
    background-color: #0FB09B !important;
    color: #fff !important;
    border-color: #0FB09B;
    width: 132px;
    height: 45px;
    margin-left: 16px;
    border-radius: 6px;
  }
  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
    display: none;
  }

}

.info-head {
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  font-weight: 600;
  color: #2e2e2e;
  padding-left: 16px;
  border-bottom: 1px solid #f0f0f2;
}
</style>